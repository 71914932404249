import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { i18n } from "../../translate/i18n";
import { Form, Formik } from "formik";
import LabelSelect from "../LabelSelect";
import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import api from "../../services/api";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box paddingTop={3} paddingBottom={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {},

  ticketNumber: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    fontSize: 14,
    fontStyle: "italic",
  },

  mainHeaderSub: {
    flex: "none",
    backgroundColor: "#eee",
  },
}));

const LabelAssignTicketModal = ({ open, onClose, ticket }) => {
  const classes = useStyles();
  const history = useHistory();

  const [selectedContactLabelIds, setSelectedContactLabelIds] = useState([]);
  const [selectedTicketLabelIds, setSelectedLabelIds] = useState([]);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (ticket && ticket.labels && ticket.labels.length > 0) {
      setSelectedLabelIds(ticket.labels.map((label) => label.id));
    }

    if (
      ticket &&
      ticket.contact &&
      ticket.contact.labels &&
      ticket.contact.labels.length > 0
    ) {
      setSelectedContactLabelIds(
        ticket.contact.labels.map((label) => label.id)
      );
    }
  }, [ticket, open]);

  const handleSaveLabel = async () => {

    try {
      await api.post("/labels/assign-contact-ticket", {
        contactId: ticket.contact.id,
        ticketId: ticket.id,
        labelContactIds: selectedContactLabelIds,
        labelTicketIds: selectedTicketLabelIds,
      });

      toast.success(`Label saved for ticket #${ticket.id} successfully`);
      handleClose();

      history.push("/tickets");
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose(false);
    setSelectedLabelIds([]);
    setSelectedContactLabelIds([]);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={() => onClose(false)}
      >
        <DialogTitle>{i18n.t("labelAssignTicketModal.title")}</DialogTitle>
        <Paper square className={classes.mainHeaderSub}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            bac
            aria-label="simple tabs example"
          >
            <Tab label="To Tikcet" {...a11yProps(0)} />
            <Tab label="To Contact" {...a11yProps(1)} />
          </Tabs>
        </Paper>
        <DialogContent>
          <TabPanel value={value} index={0}>
            <Formik
              initialValues={{
                selectedTicketLabelIds: [],
              }}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  handleSaveLabel(values);
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {() => (
                <Form>
                  <LabelSelect
                    selectedLabelIds={selectedTicketLabelIds}
                    onChange={(selectTicketLabel) =>
                      setSelectedLabelIds(selectTicketLabel)
                    }
                  />
                </Form>
              )}
            </Formik>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Formik
              initialValues={{
                selectedContactLabelIds: [],
              }}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  handleSaveLabel(values);
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {() => (
                <Form>
                  <LabelSelect
                    selectedLabelIds={selectedContactLabelIds}
                    onChange={(selectContactLabel) =>
                      setSelectedContactLabelIds(selectContactLabel)
                    }
                  />
                </Form>
              )}
            </Formik>
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="outlined" onClick={handleClose}>
            {i18n.t("labelAssignTicketModal.buttons.cancel")}
          </Button>
          <Button onClick={handleSaveLabel} color="primary" variant="contained">
            {i18n.t("labelAssignTicketModal.buttons.ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LabelAssignTicketModal;
