import React, { useEffect, useState } from "react";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import {
  Button,
  Grid,
  InputAdornment,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import useWhatsApps from "../../hooks/useWhatsApps";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { toast } from "react-toastify";
import SearchIcon from "@material-ui/icons/Search";
import { format } from "date-fns";
import useSetting from "../../hooks/useSetting";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Templates = () => {
  const classes = useStyles();

  const [templates, setTemplates] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [lastUpdated, setLastUpdated] = useState(null);

  const { whatsApps, loading: whatsAppsLoading } = useWhatsApps();
  const { settings, refreshSettings } = useSetting();

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      try {
        const { data } = await api.get("/templates", {
          params: {
            page: pageNumber,
            limit: pageSize,
            search: searchParam,
          },
        });

        setTemplates(data.templates);
        setTotalCount(data.count);
        refreshSettings();
      } catch (error) {
        toastError(error);
      }

      setLoading(false);
      setReload(false);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [pageNumber, pageSize, searchParam, reload]);

  useEffect(() => {
    if (settings && settings.length > 0) {
      const templatesFetched = settings.find(
        (setting) => setting.key === "templatesFetched"
      );
      if (templatesFetched) {
        setLastUpdated(
          format(new Date(templatesFetched.value), "dd/MM/yyyy HH:mm:ss")
        );
      }
    }
  }, [settings]);
  const handleFetchTemplates = async () => {
    if (whatsAppsLoading && whatsApps.length === 0) {
      return;
    }

    const whatsappOfficial = whatsApps.filter(
      (whatsapp) => whatsapp.channel === "whatsappOfficial"
    );

    if (whatsappOfficial.length === 0) {
      toast.error(i18n.t("templates.toasts.noOfficalConnection"));
      return;
    }

    const whatsapp = whatsappOfficial.map((whatsapp) => {
      return {
        token: whatsapp.token,
      };
    });

    setLoading(true);

    try {
      whatsapp.forEach(async (whatsApp) => {
        const { token } = whatsApp;

        await api.get("/fetch-templates/" + token);

        return whatsApp;
      });
    } catch (error) {
      toastError(error);
    }

    setReload(true);
  };

  const handlePageChange = (event, newPage) => {
    setPageNumber(newPage + 1);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(+event.target.value);
    setPageNumber(1);
  };

  const handleStatusChange = async (event, templateId) => {
    const newStatus = event.target.checked ? "active" : "inactive";

    try {
      await api.put(`/templates/${templateId}`, { status: newStatus });

      setReload(true);

      toast.success(i18n.t("templates.toasts.updated"));
    } catch (error) {
      toastError(error);
    }
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value);
    setPageNumber(1);
  };

  return (
    <MainContainer>
      <MainHeader>
        <Title>{i18n.t("templates.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFetchTemplates}
          >
            {i18n.t("templates.buttons.update")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Grid container className={classes.mainPaper}>
        <Grid item xs={12}>
          <TextField
            placeholder={i18n.t("quickAnswers.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            margin="dense"
            style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{i18n.t("templates.table.name")}</TableCell>
              <TableCell>{i18n.t("templates.table.approveStatus")}</TableCell>
              <TableCell>{i18n.t("templates.table.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {loading ? (
                <TableRowSkeleton columns={3} />
              ) : (
                templates &&
                templates.map((template) => (
                  <TableRow key={template.id}>
                    <TableCell>{template.name}</TableCell>
                    <TableCell>{template.approveStatus}</TableCell>
                    <TableCell>
                      <Switch
                        checked={template.status === "active"}
                        onChange={(e) => handleStatusChange(e, template.id)}
                        color="primary"
                        name="status"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </>
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          page={pageNumber - 1}
          onPageChange={handlePageChange}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onRowsPerPageChange={handlePageSizeChange}
        />
        {lastUpdated && (
          <Typography variant="body2" align="right">
            {i18n.t("templates.lastUpdate")} {lastUpdated}
          </Typography>
        )}
      </Paper>
    </MainContainer>
  );
};

export default Templates;
