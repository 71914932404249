import React from "react";
import { Avatar, CardHeader, Box, Typography, Chip } from "@material-ui/core";
import LabelIcon from "@material-ui/icons/Label";
import { i18n } from "../../translate/i18n";

const TicketInfo = ({ contact, ticket, onClick }) => {
  return (
    <Box>
      <CardHeader
        onClick={onClick}
        style={{ cursor: "pointer" }}
        titleTypographyProps={{ noWrap: false }}
        subheaderTypographyProps={{
          noWrap: true,
          style: { fontStyle: "italic", fontSize: 13 },
        }}
        avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
        title={
          <Box>
            <Typography variant="body2" style={{ textTransform: "uppercase" }}>
              <span style={{ fontWeight: "bold" }}>#{ticket.id}</span> &nbsp;
              <span>{contact.name}</span>
            </Typography>
            <Box display="flex" flexWrap="wrap" mt={0.5} mb={0.5} style={{ gap: 5 }}>
              {ticket?.contact?.labels && ticket.contact.labels.length > 0
                ? ticket.contact.labels.map((label) => (
                    <Chip
                      key={label.id}
                      avatar={
                        <Avatar
                          style={{
                            backgroundColor: label.color,
                            color: "#FFF",
							height: 11,
							width: 11
                          }}
                        >
                          <LabelIcon style={{ fontSize: 11, color: label.color }} />
                        </Avatar>
                      }
                      label={
						<Typography variant="caption" style={{ fontSize: 11 }}>
						  {label.name}
						</Typography>
					  }
                      size="small"
                      style={{ borderColor: "grey", color: "#000", height: 18 }}
                      variant="outlined"
                    />
                  ))
                : null}
            </Box>
          </Box>
        }
        subheader={
          ticket.user &&
          `${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`
        }
      />
    </Box>
  );
};

export default TicketInfo;
